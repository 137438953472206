export function aggregationsLocation(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/aggregationsLocation`, params);
}
  
export function aggregationsMedia(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/aggregationsMedia`, params);
}
  
export function realTimeAndSensitive(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/realTimeAndSensitive`, params)
}

export function aggregationsKeyword(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/aggregationsKeyword`, params)
}

export function emotionLineChart(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/emotionLineChart`, params)
}

export function weiboHotTopic(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/weiboHotTopic`, params)
}

export function hotTopic(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/hotTopic`, params)
}

export function hotTopicPaginate(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/hotTopicPaginate`, params)
}

export function warningMessage(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/warningMessage`, params)
}

export function aggregationsLocationForPaginate(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/aggregationsLocationForPaginate`, params)
}

export function aggregationsKeywordForPaginate(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/aggregationsKeywordForPaginate`, params)
}

export function aggregationsMediaForPaginate(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/aggregationsMediaForPaginate`, params)
}

export function realTimePaginate(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/realTimePaginate`, params)
}

export function sensitivePaginate(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/sensitivePaginate`, params)
}

export function emotionLinePaginate(params = {}) {
    return window.$axios.post(`${window.service.api}/monitor/emotionLinePaginate`, params)
}
