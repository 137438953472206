
<template>
  <div class="plan-screen screen">
    <p class="copyright" v-if="env_version === 'common'">技术支持：上海索思数据科技有限公司</p>
    <p class="copyright" v-else>{{ env_title }}</p>
    <div class="stars"></div>
    <div class="header">
      <a class="Flex spaceB" href="javascript: void(0);" target="_parent">
        <div class="w-100">
          <div class="head_title">
            <div class="flex space-between w-100">
              <p class="date">{{ nowDate }} {{ nowTime }}</p>
              <span>监测时间：24小时</span>
            </div>
            <h1 class="title">“{{ plan_name }}”舆情监测</h1>
            <img src="../../assets/images/plan/arrow_new.png" class="headImg" />
          </div>
        </div>
      </a>
    </div>
    <div class="screen-content flex">
      <div class="top-cont flex flex-1">
        <!-- 左侧 -->
        <div class="left-cont flex w-460 flexD spaceB">
          <!-- 地区声量分布 -->
          <div class="area-volumn mod-box flex-1">
            <h3 class="screen-title">地区声量分布</h3>
            <div class="cont">
              <ul class="percentage-list mar-t-10">
                <li
                  v-for="(item, i) in location"
                  :key="i"
                  @click="dqsl(item.key)"
                >
                  <div class="top" data-method="notice">
                    <span>No.{{ i + 1 }} {{ item.key }}</span
                    ><strong>{{ item.doc_count }}</strong>
                  </div>
                  <div class="bottom">
                    <div class="progress">
                      <div class="progress-bar" :style="item.percent"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!--@end 地区声量分布 -->
          <!-- 媒体类型分布 -->
          <div class="media-type mod-box flex-1 mar-t-10">
            <h3 class="screen-title">媒体类型分布</h3>
            <div class="cont">
              <div id="echarts1"></div>
            </div>
          </div>
          <!--@end 媒体类型分布 -->
        </div>
        <!-- @end 左侧-->
        <!-- 中间 -->
        <div class="center-cont flex-1 flex flexD">
          <ul class="data flex space-between">
            <li>
              <p class="tag">实时数据</p>
              <p class="num" @click="sssj()">{{ all | number_format }}</p>
            </li>
            <li>
              <p class="tag">敏感数据</p>
              <p class="num" @click="mgsj()">{{ sensitive | number_format }}</p>
            </li>
          </ul>
          <div class="earth-box flex-1 flex spaceB">
            <div class="left-aside"></div>
            <div class="Rotation earth"></div>
            <div class="tagcloud_box" ref="tagcloud">
              <!-- <tag-cloud
                :config="tagConfig"
                :data="hotTag"
                @clickTag="rmc"
                class="b"
              ></tag-cloud> -->
            </div>
            <div class="right-aside"></div>
          </div>
        </div>
        <!-- @end 中间-->
        <!-- 右侧 -->
        <div class="right-cont w-460 flex flexD spaceB">
          <!-- 情感声量走势 -->
          <div class="mod-box flex-1">
            <h3 class="screen-title">情感声量走势</h3>
            <div class="cont">
              <div id="echarts2"></div>
            </div>
          </div>
          <!--@end 情感声量走势 -->
          <!-- 微博热门话题 -->
          <div class="mod-box flex-1 hot-topic mar-t-10">
            <h3 class="screen-title">微博热门话题</h3>
            <div class="cont">
              <div class="screen-mod-cont screen-list w-100">
                <p class="title flex">
                  <span>序号</span>
                  <span class="flex-1">话题</span>
                  <span class="tag">热度</span>
                </p>
                <div class="marquee-wrap">
                  <ul class="marquee-list" :class="{ 'animate-up': animateUp }">
                    <li
                      v-for="(item, index) in listData"
                      :key="index"
                      class="item"
                      @click="redirect(item._source.source_url_s)"
                    >
                      <span class="num">{{ item.sort_num }}</span>
                      <a class="screen-list-title" :title="item">{{
                        item._source.title
                      }}</a>
                      <span class="tag">{{item.sort[0]}}</span>
                      <!-- <span class="tag"
                        >{{
                          Math.ceil(item.sort[0] / 10000) > 0
                            ? Math.ceil(item.sort[0] / 10000)
                            : 1
                        }}w</span
                      > -->
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--@end 微博热门话题 -->
        </div>
        <!-- @end 右侧 -->
      </div>
      <div class="bottom-cont flex mar-t-10">
        <!-- 预警信息 -->
        <div class="mod-box flex-1 caution-information mar-r-20 ov">
          <div class="screen-title flex space-between">
            <h3>预警信息</h3>
            <span class="more" v-if="warning.empty == false ? 'hidden' : ''" @click="warningPageChange" >more</span>
          </div>
          <div class="cont">
            <div class="echarts_empty" v-if="warning.empty">
              <div class="img"></div>
            </div>
            <div class="screen-mod-cont screen-list w-100" v-else>
              <p class="title flex">
                <span>序号</span>
                <span class="flex-1">预警内容</span>
                <span class="tag">预警类型</span>
                <span class="time">预警时间</span>
              </p>

              <div class="marquee-wrap">
                <ul class="marquee-list">
                  <li v-for="(item, i) in warning.data" :key="i" class="item" @click="warningDetail(item.messageId)">
                    <span class="num">{{ i + 1 }}</span>
                    <a class="screen-list-title" :title="item.content">{{
                      item.content
                    }}</a>
                    <span class="tag" title="">{{
                      item.warningType
                    }}</span>
                    <span class="time">{{ item.pushTime }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--@end 预警信息 -->
        <!-- 热门信息 -->
        <div class="mod-box flex-1 hot-information ov">
          <div class="screen-title flex space-between">
            <h3>热门信息</h3>
            <span class="more" @click="modalList('热门信息')">more</span>
          </div>
          <div class="cont">
            <div class="screen-mod-cont screen-list w-100">
              <p class="title flex">
                <span>序号</span>
                <span class="flex-1">话题</span>
                <span class="time">时间</span>
                <span class="tag">来源</span>
              </p>
              <div class="marquee-wrap">
                <ul class="marquee-list">
                  <li
                    v-for="(item, i) in hotNews"
                    :key="i"
                    class="item"
                    @click="redirect(item._source.source_url_s)"
                  >
                    <span class="num">{{ i + 1 }}</span>
                    <a class="screen-list-title" :title="item._source.title">
                      {{ item._source.title }}
                    </a>
                    <span class="time">{{ item._source.published_d }}</span>
                    <span class="tag" title="腾讯网">{{
                      item._source.source_name_s
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--@end 热门信息 -->
      </div>
    </div>
    <!-- 列表弹出框 -->
    <el-dialog
      class="screen-dialog"
      :visible.sync="dialogVisible1"
      :modal-append-to-body="false"
    >
      <h3 class="screen-dialog-title" slot="title">
        {{ modalData.title }}信息列表
      </h3>
      <div class="cont">
        <div class="flex space-between w-100 sub-title">
          <span>{{ modalData.title_co }}信息 {{ modalData.total }}条</span>
          <div>
            <!-- <el-form ref="form" :model="form" label-width="100px" class="f-r"
              ><el-form-item label="排序方式">
                <el-select v-model="form.region" placeholder="智能排序">
                  <el-option label="智能排序" value="1"></el-option>
                  <el-option label="时间降序" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-link icon="iconfont icon-xiazai" class="f-r mar-r-30"
              >导出Excel</el-link
            > -->
          </div>
        </div>
        <el-row class="xxlb-title mar-t-20">
          <el-col :span="14">标题</el-col>
          <el-col :span="3">相似文章数</el-col>
          <el-col :span="2">来源</el-col>
          <el-col :span="2">作者</el-col>
          <el-col :span="3">时间</el-col>
        </el-row>
        <div class="flex-1" style="overflow: auto">
          <ul class="xxlb">
            <li class="item" v-for="(item, i) in paginateData.data" :key="i">
              <el-row class="flex">
                <el-col :span="14">
                  <h3 class="w-100 ov-h">
                    <el-button
                      type="primary"
                      class="fmg"
                      v-if="item._source.emotion_i > 50"
                      >非敏感</el-button
                    >
                    <el-button type="primary" class="mg" v-else>敏感</el-button>
                    <span
                      class="icon-yuanfabiaoqian"
                      v-if="
                        item._source.category_s == '微博' &&
                        item.isforward_i == 0
                      "
                      >原</span
                    >
                    <span
                      class="icon-zhuanfabiaoqian"
                      v-if="
                        item._source.category_s == '微博' &&
                        item.isforward_i == 1
                      "
                      >转</span
                    >
                    <!-- <a class="title" v-html="item.title" @click="showDetail(item)"></a> -->
                    <a
                      @click="redirect(item._source.source_url_s)"
                      class="title"
                      >{{ item._source.title }}</a
                    >
                  </h3>
                  <p class="summary" v-html="item._source.content"></p>
                  <p class="info">
                    <span>媒体类型：{{ item._source.category_s }}</span>
                    <span
                      >信息地区：{{
                        item._source.province_s
                          ? item._source.province_s
                          : item._source.location_s
                          ? item._source.location_s
                          : "--"
                      }}</span
                    >
                    <!-- <span>涉及词：{{ join(item._source.keyword_s,',') }}</span> -->
                  </p>
                </el-col>
                <el-col :span="3" class="t-c">1</el-col>
                <el-col :span="2" class="t-c">{{
                  item._source.source_name_s
                }}</el-col>
                <el-col :span="2" class="t-c">{{ item._source.site_s }}</el-col>
                <el-col :span="3" class="t-c">{{
                  item._source.published_d
                }}</el-col>
              </el-row>
            </li>
          </ul>
        </div>
        <div class="t-c pb-10">
          <el-pagination
            layout="prev, pager, next"
            :total="paginateData.total"
            :hide-on-single-page="singPage"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      class="screen-dialog"
      :visible.sync="dialogVisible2"
      :modal-append-to-body="false"
    >
      <h3 class="screen-dialog-title" slot="title">
        “{{ warning.title }}”信息列表
      </h3>
      <div class="cont">
        <div class="flex space-between w-100 sub-title">
          <!-- <span>{{ warning.title_co }}信息 {{ warning.total }}条</span> -->
          <span>{{ warning.title_co }}</span>
          <div>
            <!-- <el-form ref="form" :model="form" label-width="100px" class="f-r"
              ><el-form-item label="排序方式">
                <el-select v-model="form.region" placeholder="智能排序">
                  <el-option label="智能排序" value="1"></el-option>
                  <el-option label="时间降序" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-link icon="iconfont icon-xiazai" class="f-r mar-r-30"
              >导出Excel</el-link
            > -->
          </div>
        </div>
        <el-row class="xxlb-title mar-t-20">
          <el-col :span="12">预警内容</el-col>
          <el-col :span="2">预警等级</el-col>
          <el-col :span="4">预警类型</el-col>
          <el-col :span="6">预警时间</el-col>
        </el-row>
        <div class="flex-1" style="overflow: auto">
          <ul class="xxlb">
            <li
              class="item"
              v-for="(item, i) in warning.data"
              :key="i"
              @click="warningDetail(item.messageId)"
              style="cursor: pointer"
            >
              <el-row>
                <el-col :span="12">{{ item.content }}</el-col>
                <el-col :span="2" class="t-c">{{ item.grade }}</el-col>
                <el-col :span="4" class="t-c">{{ item.warningType }}</el-col>
                <el-col :span="6" class="t-c">{{ item.pushTime }}</el-col>
              </el-row>
            </li>
          </ul>
        </div>
        <div class="t-c pb-10">
          <el-pagination
            layout="prev, pager, next"
            :total="warning.total"
            :hide-on-single-page="singPage"
            @current-change="warningPageChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <!--@end 列表弹出框 -->
    <!-- 详情弹出框 -->
    <el-dialog
      class="screen-dialog article-dialog"
      :visible.sync="dialogVisible3"
      :modal-append-to-body="false"
    >
      <h3 class="screen-dialog-title" slot="title">信息详情</h3>
      <div class="cont">
        <h4 class="article-title t-c">
          <a>最前线|鸿蒙系统未至,荣耀智慧屏先带来了鸿鹄芯</a>
          <el-button type="primary" class="fmg">非敏感</el-button>
          <!-- <el-button type="primary" class="mg">敏感</el-button> -->
        </h4>
        <p class="info t-c">
          <span>媒体类型：网媒</span>
          <span>来源：搜狐号</span>
          <span>作者：宇宙安安喵</span>
          <span>信源地区：北京</span>
          <span>发布时间：2019-11-20 13:17:00</span>
          <el-button type="primary" size="mini" plain>查看原文</el-button>
        </p>
        <div class="article-cont">
          <p>
            7月26日，GMIC大会上，荣耀总裁赵明介绍了荣耀智慧屏的最新动态。今日起，荣耀智慧屏在各大电商平台开启预约，并将在8月的华为开发者大会上正式发布。
          </p>
          <p>
            荣耀智慧屏将采用鸿鹄818智慧芯片，内置两个A73 CPU、两个A53
            CPU和4个Mail-G51
            GPU共计八个核心。该芯片集成Histen音质优化技术，并具备6400万像素图片解码能力和8K@30fps、4K@120fps的视频解码能力。
          </p>
          <p>
            这是海思鸿鹄芯片第一次走向大众的视野，“鸿鹄”也成为继麒麟、巴龙、鲲鹏、昇腾、天罡等之后，海思芯片家族拥有“国风”命名的最新成员。值得一提的是，任正非透露，鸿蒙操作系统将首先使用在手表、智能8K大屏、车联网上，也有人猜测，荣耀智慧屏可能会是鸿蒙系统的首秀。
          </p>
          <p>
            此外，荣耀智慧屏还拥有海思NPU芯片加持的升降式AI摄像头。近期荣耀发布的荣耀9X同样采用AI升降摄像头。
          </p>
          <p>最前线 | 鸿蒙系统未至，荣耀智慧屏先带来了鸿鹄芯片</p>
          <p>
            赵明的演讲中对荣耀智慧屏谈论不多，主要是为这款产品的预约和发布做个预热。但荣耀进军电视行业的一举一动都备受关注，华为公司并不想引起传统电视行业合作伙伴的戒备，赵明也再次强调：“我们不做电视，我们做电视的未来。”此前，荣耀方面透露，荣耀智慧屏将会通过近场通信技术（NFC）打通屏幕与屏幕，实现平板电脑、智慧屏、手机之间的连接。
          </p>
          <p>
            赵明用了更多的时间谈论荣耀的理念和战略。他表示，目前荣耀已稳居中国互联网手机第一品牌，未来荣耀的目标是中国全渠道前二和全球前四。这意味着荣耀需要在出货量上超越小米、OV。
          </p>
          <p>
            当前，中国的手机行业连续三年11%以上下滑，消费者换机周期加长，并且在5G到来之前持币待购，这让所有手机厂商处境更加困难。赵明说：“友商的困难、行业的困难我们都有，我们还有其他厂商没有的困难（美国禁令的压力）。”
          </p>
          <p>
            今年5月21日，荣耀在伦敦发布荣耀20系列手机。此前不久，华为被美国商务部列入实体名单。赵明提到，一些合作伙伴曾劝他不要在这个当口发布新品，而且有些软件也要重新测试。
          </p>
          <p>
            但美国的禁令没有阻挡华为公司的业绩增长。彭博援引知情人士称，华为今年上半年收入增长约30%，明显高于此前两年同期增速。任正非还将全年预期出货量调高至2.7亿台，此前华为的目标是全年出货量达到2.5亿台。
          </p>
          <p>
            赵明的演讲中对荣耀智慧屏谈论不多，主要是为这款产品的预约和发布做个预热。但荣耀进军电视行业的一举一动都备受关注，华为公司并不想引起传统电视行业合作伙伴的戒备，赵明也再次强调：“我们不做电视，我们做电视的未来。”此前，荣耀方面透露，荣耀智慧屏将会通过近场通信技术（NFC）打通屏幕与屏幕，实现平板电脑、智慧屏、手机之间的连接。
          </p>
          <p>
            赵明用了更多的时间谈论荣耀的理念和战略。他表示，目前荣耀已稳居中国互联网手机第一品牌，未来荣耀的目标是中国全渠道前二和全球前四。这意味着荣耀需要在出货量上超越小米、OV。
          </p>
          <p>
            当前，中国的手机行业连续三年11%以上下滑，消费者换机周期加长，并且在5G到来之前持币待购，这让所有手机厂商处境更加困难。赵明说：“友商的困难、行业的困难我们都有，我们还有其他厂商没有的困难（美国禁令的压力）。”
          </p>
        </div>
      </div>
    </el-dialog>
    <!--@end 详情弹出框 -->
  </div>
</template>
<script>
import * as echarts from "echarts";
import {
  aggregationsLocation,
  aggregationsMedia,
  realTimeAndSensitive,
  aggregationsKeyword,
  emotionLineChart,
  weiboHotTopic,
  hotTopic,
  hotTopicPaginate,
  aggregationsLocationForPaginate,
  aggregationsKeywordForPaginate,
  aggregationsMediaForPaginate,
  sensitivePaginate,
  realTimePaginate,
  emotionLinePaginate,
} from "@/api/screen";
import { lists as pushList } from "@/api/caution";
const rem = () => {
  let designSize = 1920;
  let html = document.documentElement;
  let wW = html.clientWidth;
  let rem = (wW / designSize) * 625;
  $("html").css("font-size", rem + "%");
};
window.onresize = rem;
rem();
export default {
  data() {
    return {
      location: [],
      all: 0,
      sensitive: 0,

      timer: "", //定义一个定时器的变量
      timer2: "",
      timer3: "",
      animateUp: false,
      nowDate: null, //存放年月日变量
      nowTime: null, //存放时分秒变量
      listData: [],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      singPage: true,
      form: {
        region: "",
      },
      data: [
        {
          emotion: "positive",
          category: "微博",
          isforward: 0,
          title: "陆军喷火器实训现场 “火龙”腾飞场面超燃",
          description:
            "原标题：陆军喷火器实训现场 “火龙”腾飞场面超燃 　　3月23日，中国人民解放军东部战区陆军发布一段视频。第73集团军某旅防化连组织防化专业喷火器实喷训练，现场枪膛怒吼、火光冲天，场面震撼。网友评论：“好酷啊”“超燃”……陆军喷火器实训现场 “火龙”腾飞场面超燃",
          word: "中国人民解放军东部战区",
        },
        {
          emotion: "negative",
          category: "微博",
          isforward: 1,
          title: "陆军喷火器实训现场 “火龙”腾飞场面超燃",
          description:
            "原标题：陆军喷火器实训现场 “火龙”腾飞场面超燃 　　3月23日，中国人民解放军东部战区陆军发布一段视频。第73集团军某旅防化连组织防化专业喷火器实喷训练，现场枪膛怒吼、火光冲天，场面震撼。网友评论：“好酷啊”“超燃”……陆军喷火器实训现场 “火龙”腾飞场面超燃",
          word: "中国人民解放军东部战区",
        },
      ],
      hotTag: [],
      hotNews: [],
      paginateData: {
        page: 1,
        total: 0,
        total_page: 0,
        limit: 10,
        data: [],
      },
      requestData: {
        plan_id: this.$route.query.plan_id,
      },
      plan_name: this.$route.query.plan_name,
      modalData: {
        title: "",
        title_co: "",
        total: 0,
        type: "",
      },
      warning: {
        total: 0,
        empty: true,
        data: [],
        title: "",
        title_co: "",
      },
      tagConfig: {
        radius: 200, //滚动半径，Number，单位px
        maxFont: 24, //最大字体大小
        color: null, //字体颜色。为null时随机
        rotateAngleXbase: 1000, //	X方向旋转速度基数，数越小速度越快
        rotateAngleYbase: 1000, //	Y方向旋转速度基数，数越小速度越快
        hover: false, //是否开启悬浮联动
      },
      province: "",
      category: "",
      rmcs: "",
      category_tigger: 0,
      qgsl_d: {},
      env_version: process.env.VUE_APP_VERSION,
      env_title: process.env.VUE_APP_TITLE
    };
  },
  watch: {
    dialogVisible1: function (val) {
      if (!val) {
        this.paginateData.page = 1;
      }
    }
  },
  filters: {
    number_format: function (number, decimals, decPoint, thousandsSep) {
      number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
      const n = !isFinite(+number) ? 0 : +number;
      const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
      const sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
      const dec = typeof decPoint === "undefined" ? "." : decPoint;
      let s = "";
      const toFixedFix = function (n, prec) {
        if (("" + n).indexOf("e") === -1) {
          return +(Math.round(n + "e+" + prec) + "e-" + prec);
        } else {
          const arr = ("" + n).split("e");
          let sig = "";
          if (+arr[1] + prec > 0) {
            sig = "+";
          }
          return (+(
            Math.round(+arr[0] + "e" + sig + (+arr[1] + prec)) +
            "e-" +
            prec
          )).toFixed(prec);
        }
      };
      s = (prec ? toFixedFix(n, prec).toString() : "" + Math.round(n)).split(
        "."
      );
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
  created() {
    // 时间
    this.timer = setInterval(this.getTime, 1000);
    // 向上滚动
    this.timer2 = setInterval(this.scrollAnimate, 3000);
    // 头部动画
    this.timer3 = setInterval(this.headImg, 3000);
  },
  mounted() {
    this.loadData();
    this.tagCloudEventListener();
  },
  methods: {
    tagCloudEventListener() {
      let that = this;
      let rootEl = document.querySelector(".tagcloud_box");
      rootEl.addEventListener("click", function clickEventHandler(e) {
        if (e.target.className === "tagcloud--item") {
          that.rmc(e.target.innerText);
        }
      });
    },
    pageChange(val) {
      if (this.modalData.type == "热门信息") {
        this.paginateData.page = val;
        this.modalList("热门信息");
      }
      if (this.modalData.type == "地区声量") {
        this.dqsl(this.province, val);
      }
      if (this.modalData.type == "热门词") {
        this.rmc(this.rmcs, val);
      }
      if (this.modalData.type == "媒体类型") {
        this.mtlx(this.category, val);
      }
      if (this.modalData.type == "实时数据") {
        this.sssj(val);
      }
      if (this.modalData.type == "敏感数据") {
        this.mgsj(val);
      }
      if (this.modalData.type == "情感声量走势") {
        this.qgsl(this.qgsl_d, val);
      }
    },
    warningPageChange(val = 1) {
      pushList({
        plan_id: this.requestData.plan_id,
        customer_id: this.$store.state.user.user.customer_id,
        sort: "pushTime",
        limit: 10,
        current_page: val,
      })
      .then((res) => {
        if (res.data.state) {
          let result = res.data.data;
          this.warning.title = "预警信息";
          this.warning.title_co = "预警信息";
          this.warning.total = result.total;
          this.warning.empty = _.size(result.msg) < 1 ? true : false;
          this.warning.data = result.msg;
          this.dialogVisible2 = true;
        } else {
          this.$message.error(res.data.error);
        }
        this.loading = false;
      })
      .catch((err) => {
        window.console.error(err);
        this.$message.error("获取预警信息失败，服务错误");
      });
    },
    modalList(type) {
      if (type == "热门信息") {
        this.dialogVisible1 = true;
        hotTopicPaginate({
          plan_id: this.requestData.plan_id,
          page: this.paginateData.page,
          limit: this.paginateData.limit,
        })
          .then((res) => {
            if (res.data.state) {
              this.modalData.type = "热门信息";
              this.modalData.title = "热门信息";
              this.modalData.title_co = "热门信息";
              this.modalData.total = res.data.data.hits.total;
              this.paginateData.data = res.data.data.hits.hits;
              this.paginateData.total = res.data.data.hits.total;
              this.paginateData.total_page = parseInt(
                res.data.data.hits.total / this.paginateData.limit
              );
            } else {
              this.$message.error(res.data.error);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    redirect(url) {
      window.open(url);
    },
    loadData() {
      //地区声量分布
      aggregationsLocation(this.requestData)
        .then((res) => {
          if (res.data.state) {
            let data = res.data.data.aggregations.location.buckets;
            let mot = data[0].doc_count;
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              element.percent =
                "width:" + Math.ceil((element.doc_count / mot) * 100) + "%";
              this.location.push(element);
            }
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //媒体类型分布
      aggregationsMedia(this.requestData)
        .then((res) => {
          if (res.data.state) {
            let data = res.data.data.aggregations.category.buckets;
            let mt = 0;
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              if (element.key == "论坛") {
                continue;
              }
              mt += element.doc_count;
            }
            let rs = [];
            for (let j = 0; j < data.length; j++) {
              const element = data[j];
              if (element.key == "论坛") {
                continue;
              }
              rs.push({
                value: element.doc_count,
                name:
                  element.key + Math.ceil((element.doc_count / mt) * 100) + "%",
                name_s: element.key,
              });
            }
            var chartDom = document.getElementById("echarts1");
            var myChart = echarts.init(chartDom, "dark");
            var option;
            option = {
              backgroundColor: "#021E36",
              legend: {
                show: false,
              },
              toolbox: {
                show: false,
              },
              color: [
                "#0068b7",
                "#66CB03",
                "#FFB146",
                "#4E88FE",
                "#2a38d8",
                "#76D4FF",
                "#74CCB1",
              ],
              series: [
                {
                  name: "面积模式",
                  type: "pie",
                  radius: [40, 80],
                  center: ["50%", "50%"],
                  roseType: "radius",
                  itemStyle: {
                    borderRadius: 30,
                  },
                  data: rs,
                },
              ],
            };
            option && myChart.setOption(option);
            window.addEventListener("resize", () => {
              myChart.resize();
            });
            var that = this;
            myChart.on("click", function (params) {
              this.category = params.data.name_s;
              that.mtlx(params.data.name_s);
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //实时数据和敏感数据
      realTimeAndSensitive(this.requestData)
        .then((res) => {
          if (res.data.state) {
            this.all = res.data.data.aggregations.all.buckets[0].doc_count;
            this.sensitive =
              res.data.data.aggregations.sensitive.buckets[0].doc_count;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //热门词
      aggregationsKeyword(this.requestData)
        .then((res) => {
          if (res.data.state) {
            let data = res.data.data.aggregations.sensitive.buckets;
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              this.hotTag.push(element.key);
            }
            //tag cloud
            const TagCloud = require("TagCloud");
            const container = ".tagcloud_box";
            const texts = this.hotTag;
            const options = {
              radius: $(this.$refs.tagcloud).width() / 2,
              initSpeed: "slow",
              maxSpeed: "normal",
            };
            TagCloud(container, texts, options);
            const colors = ["#FFFFFF", "#0ef", "#FFCD42"];
            $(this.$refs.tagcloud)
              .children()
              .children()
              .each(function (key, item) {
                let color = colors[Math.floor(Math.random() * colors.length)];
                $(item).css("color", color);
              });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //情感走势
      emotionLineChart(this.requestData)
        .then((res) => {
          if (res.data.state) {
            let non_sensitive__pr =
              res.data.data.non_sensitive.aggregations.non_sensitive.buckets;
            let sensitive__pr =
              res.data.data.sensitive.aggregations.sensitive.buckets;
            let non_sensitive = [];
            let sensitive = [];
            for (let i = 0; i < non_sensitive__pr.length; i++) {
              const element = non_sensitive__pr[i];
              non_sensitive.push(element.doc_count);
            }
            for (let i = 0; i < sensitive__pr.length; i++) {
              const element = sensitive__pr[i];
              sensitive.push(element.doc_count);
            }
            var chartDom = document.getElementById("echarts2");
            var myChart = echarts.init(chartDom, "dark");
            var option;

            option = {
              color: ["#E21D20", "#2697ED"],
              backgroundColor: "#021E36",
              title: {
                show: false,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#6a7985",
                  },
                },
              },
              legend: {
                data: ["敏感", "非敏感"],
                padding: [20, 0, 0, 0],
              },
              toolbox: {
                show: false,
              },
              grid: {
                left: "0",
                right: "20px",
                bottom: "0",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: [
                    "00:00",
                    "04:00",
                    "08:00",
                    "12:00",
                    "16:00",
                    "20:00",
                    "24:00",
                  ],
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: "#74818F",
                      fontSize: 14,
                    },
                  },
                  axisLine: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                },
              ],
              yAxis: [
                {
                  type: "value",
                  color: "#74818F",
                  splitLine: {
                    //show: false,
                    lineStyle: {
                      color: "#253B55",
                    },
                  },
                  axisLabel: {
                    show: true,
                    textStyle: {
                      color: "#74818F",
                      fontSize: 14,
                    },
                  },
                  axisLine: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                },
              ],
              series: [
                {
                  name: "敏感",
                  type: "line",
                  stack: "总量",
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 2,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgb(206, 27, 35)",
                      },
                      {
                        offset: 1,
                        color: "rgb(246, 234, 235)",
                      },
                    ]),
                  },
                  areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgb(143, 29, 42)",
                      },
                      {
                        offset: 1,
                        color: "rgb(10, 31, 54)",
                      },
                    ]),
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: sensitive,
                },
                {
                  name: "非敏感",
                  type: "line",
                  stack: "总量",
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 2,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgb(173, 210, 244)",
                      },
                      {
                        offset: 1,
                        color: "rgb(39, 151, 238)",
                      },
                    ]),
                  },
                  areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgb(9, 67, 121)",
                      },
                      {
                        offset: 1,
                        color: "rgb(21, 45, 69)",
                      },
                    ]),
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: non_sensitive,
                },
              ],
            };
            option && myChart.setOption(option);
            window.addEventListener("resize", () => {
              myChart.resize();
            });

            var that = this;
            myChart.on("click", function (params) {
              that.qgsl(params);
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //微博热门话题
      weiboHotTopic(this.requestData)
        .then((res) => {
          if (res.data.state) {
            for (let i = 0; i < res.data.data.hits.hits.length; i++) {
              res.data.data.hits.hits[i].sort_num = i + 1;
            }
            this.listData = res.data.data.hits.hits;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //热门信息
      hotTopic(this.requestData)
        .then((res) => {
          if (res.data.state) {
            this.hotNews = res.data.data.hits.hits;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      pushList({
        plan_id: this.requestData.plan_id,
        customer_id: this.$store.state.user.user.customer_id,
        current_page: 1,
        sort: "pushTime",
        limit: this.paginateData.limit,
      })
        .then((res) => {
          if (res.data.state) {
            let result = res.data.data;
            this.warning.total = result.total;
            this.warning.empty = _.size(result.msg) < 1 ? true : false;
            this.warning.data = result.msg;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch((err) => {
          window.console.error(err);
          this.$message.error("获取预警信息失败，服务错误");
        });
    },

    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      this.month = check(month);
      this.day = check(day);
      this.hour = check(hour);
      this.minute = check(minute);
      this.second = check(second);
      function check(i) {
        const num = i < 10 ? "0" + i : i;
        return num;
      }
      this.nowDate = year + "年" + this.month + "月" + this.day + "日";
      this.nowTime = this.hour + ":" + this.minute + ":" + this.second;
    },

    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
      }
    },

    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.listData.push(this.listData[0]);
        this.listData.shift();
        this.animateUp = false;
      }, 500);
    },

    destroyed() {
      clearInterval(this.timer2);
    },

    headImg() {
      setTimeout(() => {
        $(".headImg").show();
        $(".headImg").addClass("arrow_img");
      }, 3000);
    },

    clickTagItem(tag) {
      // TODO
      console.log(tag);
    },

    dqsl(val, page = 1) {
      this.province = val;
      aggregationsLocationForPaginate({
        plan_id: this.requestData.plan_id,
        province: val,
        current_page: this.paginateData.page,
        limit: this.paginateData.limit,
        page: page,
      })
        .then((res) => {
          if (res.data.state) {
            this.modalData.type = "地区声量";
            this.modalData.title = "地区声量分布";
            this.modalData.title_co = val + "地区";
            this.modalData.total = res.data.data.hits.total;
            this.paginateData.data = res.data.data.hits.hits;
            this.paginateData.total = res.data.data.hits.total;
            this.paginateData.total_page = parseInt(
              res.data.data.hits.total / this.paginateData.limit
            );
            this.dialogVisible1 = true;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch((err) => {
          window.console.error(err);
          this.$message.error("获取信息失败，服务错误");
        });
    },

    rmc(val, page = 1) {
      this.rmcs = val;
      aggregationsKeywordForPaginate({
        plan_id: this.requestData.plan_id,
        keyword: val,
        current_page: this.paginateData.page,
        limit: this.paginateData.limit,
        page: page,
      })
        .then((res) => {
          if (res.data.state) {
            let result = res.data.data;
            this.modalData.type = "热门词";
            this.modalData.title = "热门词";
            this.modalData.title_co = val;
            this.modalData.total = res.data.data.hits.total;
            this.paginateData.data = res.data.data.hits.hits;
            this.paginateData.total = res.data.data.hits.total;
            this.paginateData.total_page = parseInt(
              res.data.data.hits.total / this.paginateData.limit
            );
            this.dialogVisible1 = true;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch((err) => {
          window.console.error(err);
          this.$message.error("获取信息失败，服务错误");
        });
    },

    mtlx(val, page = 1) {
      this.category = val;
      aggregationsMediaForPaginate({
        plan_id: this.requestData.plan_id,
        category: val,
        current_page: this.paginateData.page,
        limit: this.paginateData.limit,
        page: page,
      })
        .then((res) => {
          if (res.data.state) {
            this.modalData.type = "媒体类型";
            this.modalData.title = "媒体类型";
            this.modalData.title_co = val;
            this.modalData.total = res.data.data.hits.total;
            this.paginateData.data = res.data.data.hits.hits;
            this.paginateData.total = res.data.data.hits.total;
            this.paginateData.total_page = parseInt(
              res.data.data.hits.total / this.paginateData.limit
            );
            this.dialogVisible1 = true;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch((err) => {
          window.console.error(err);
          this.$message.error("获取信息失败，服务错误");
        });
    },

    sssj(page = 1) {
      realTimePaginate({
        plan_id: this.requestData.plan_id,
        current_page: this.paginateData.page,
        limit: this.paginateData.limit,
        page: page,
      })
        .then((res) => {
          if (res.data.state) {
            this.modalData.type = "实时数据";
            this.modalData.title = "实时数据";
            this.modalData.title_co = "实时数据";
            this.modalData.total = res.data.data.hits.total;
            this.paginateData.data = res.data.data.hits.hits;
            this.paginateData.total = res.data.data.hits.total;
            this.paginateData.total_page = parseInt(
              res.data.data.hits.total / this.paginateData.limit
            );
            this.dialogVisible1 = true;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch((err) => {
          window.console.error(err);
          this.$message.error("获取信息失败，服务错误");
        });
    },

    mgsj(page = 1) {
      sensitivePaginate({
        plan_id: this.requestData.plan_id,
        current_page: this.paginateData.page,
        limit: this.paginateData.limit,
        page: page,
      })
        .then((res) => {
          if (res.data.state) {
            this.modalData.type = "敏感数据";
            this.modalData.title = "敏感数据";
            this.modalData.title_co = "敏感数据";
            this.modalData.total = res.data.data.hits.total;
            this.paginateData.data = res.data.data.hits.hits;
            this.paginateData.total = res.data.data.hits.total;
            this.paginateData.total_page = parseInt(
              res.data.data.hits.total / this.paginateData.limit
            );
            this.dialogVisible1 = true;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch((err) => {
          window.console.error(err);
          this.$message.error("获取信息失败，服务错误");
        });
    },

    qgsl(data, page = 1) {
      this.qgsl_d = data;
      emotionLinePaginate({
        type: data.seriesName,
        time_end: data.name,
        plan_id: this.requestData.plan_id,
        current_page: this.paginateData.page,
        limit: this.paginateData.limit,
        page: page,
      })
        .then((res) => {
          if (res.data.state) {
            this.modalData.type = "情感声量走势";
            this.modalData.title = "情感声量走势";
            this.modalData.title_co = "情感声量走势";
            this.modalData.total = res.data.data.hits.total;
            this.paginateData.data = res.data.data.hits.hits;
            this.paginateData.total = res.data.data.hits.total;
            this.paginateData.total_page = parseInt(
              res.data.data.hits.total / this.paginateData.limit
            );
            this.dialogVisible1 = true;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch((err) => {
          window.console.error(err);
          this.$message.error("获取信息失败，服务错误");
        });
    },

    warningDetail(msid) {
      window.open(`/caution/list?messageId=${msid}`, "_blank");
    },

    mt_rand(min, max) {
      const argc = arguments.length;
      if (argc === 0) {
        min = 0;
        max = 2147483647;
      } else if (argc === 1) {
        throw new Error(
          "Warning: mt_rand() expects exactly 2 parameters, 1 given"
        );
      } else {
        min = parseInt(min, 10);
        max = parseInt(max, 10);
      }
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
}
.tagcloud_box span {
  cursor: pointer;
}
</style>
<style scoped>
.screen div,
.screen h3,
.screen p,
.screen a,
.screen span,
.screen a:hover {
  color: #fff;
}
.flexD {
  flex-direction: column;
}
.plan-screen {
  background-image: url(../../assets/images/plan/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  background-position: 0 0;
  box-sizing: border-box;
  position: relative;
  perspective: 3.4rem;
  overflow: hidden;
}
/* 银河例子动画 */
.stars {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.02rem;
  height: 0.02rem;
  animation: fly 3s linear infinite;
  transform-style: preserve-3d;
  box-shadow: -2.24rem 1.08rem #f7f7f7, 1.93rem -3.76rem #f7f7f7,
    -12.64rem -0.6rem #fafafa, 1.45rem -4.43rem #c9c9c9,
    -14.4rem 0.37rem #f7f7f7, 2.62rem -1.36rem #cfcfcf, 2.12rem 2.19rem #e3e3e3,
    3.19rem 2.23rem #c7c7c7, 12.46rem -0.51rem #c9c9c9, 3.08rem 0.26rem #e0e0e0,
    1.47rem 2.29rem #e6e6e6, -10.41rem 4.5rem #d1d1d1, 6.71rem 0.86rem white,
    -9.21rem 2.34rem #f0f0f0, -9.57rem 2.42rem #e8e8e8,
    12.49rem -2.85rem #d1d1d1, 7.41rem 4.34rem #d9d9d9, -10.3rem 3.3rem #ededed,
    3.11rem -4.75rem #fcfcfc, -4.13rem -1.05rem #d4d4d4,
    8.68rem -4.35rem #f7f7f7, -7.9rem 2.89rem #dbdbdb, 2.02rem 4.27rem #fcfcfc,
    -0.22rem -1.58rem #c9c9c9, -12.94rem -2.97rem #cccccc, -0.63rem 2rem #f0f0f0,
    -5.92rem -0.86rem #f7f7f7, -7.32rem 3.66rem white,
    -14.26rem -2.62rem #e8e8e8, 4.75rem -4rem #c9c9c9,
    -14.42rem -1.53rem #f7f7f7, 11.24rem -2.19rem #ededed,
    7.01rem 2.57rem #f0f0f0, 0.36rem -1.79rem #ebebeb, -9.37rem 4.8rem #f7f7f7,
    -2.67rem -4.18rem #f2f2f2, 14.67rem 1.27rem #cfcfcf,
    -11.28rem 2.74rem #d1d1d1, -1.8rem 4.3rem #e0e0e0, 6.24rem 2.75rem #fafafa,
    -4.73rem 1.5rem #e6e6e6, -5.37rem -3.48rem #d4d4d4, 4.1rem 4.55rem #ebebeb,
    4.97rem -0.29rem #cccccc, 4.16rem -3.7rem whitesmoke,
    9.77rem 0.82rem #c2c2c2, 12.09rem -2.22rem #f0f0f0, 6.37rem 3.6rem #fcfcfc,
    -10.49rem 4.09rem white, 9.59rem 4.07rem #c9c9c9, -11.53rem -1.68rem #dedede,
    -4.37rem -2.16rem #dbdbdb, -5.72rem 4.2rem #fcfcfc, -2.28rem 0.78rem #dbdbdb,
    -9.25rem 4.52rem #cccccc, 2.44rem 0.3rem #c4c4c4, 4.53rem -3.36rem #dedede,
    9.93rem -0.63rem #c2c2c2, 6.7rem -3.43rem #d9d9d9, -10.88rem 3.44rem #fcfcfc,
    -1.7rem -1.53rem #c4c4c4, 9.87rem -2.08rem #d1d1d1, 5.96rem 1.66rem #fcfcfc,
    -13.62rem -0.28rem #c4c4c4, 12.91rem -1.06rem #e3e3e3,
    -11.28rem 1.69rem #ededed, 10.56rem 3.67rem #ebebeb,
    2.61rem -1.28rem #cccccc, 10.17rem 4.01rem #cccccc, 13.81rem 2.45rem #e3e3e3,
    5.36rem -1.13rem #fafafa, -0.92rem -2.17rem #e3e3e3,
    14.81rem 2.68rem #d1d1d1, 8.32rem -3.45rem #cccccc, -8.63rem 1.38rem #f0f0f0,
    -9.97rem -4.12rem #e3e3e3, -3.3rem -2.6rem #d9d9d9, 6.91rem -0.39rem #fcfcfc,
    -4.68rem -1.35rem #d6d6d6, -5.35rem 4.05rem #e8e8e8,
    -12.89rem -2.84rem #e3e3e3, 8.23rem -2.25rem #cccccc,
    -11.72rem -3.48rem white, 5.05rem -3.19rem #ededed, 0.94rem -0.45rem #ededed,
    -6.87rem -0.64rem #c7c7c7, -11.33rem -4.56rem #f2f2f2,
    13.57rem 1.35rem #e0e0e0, -9.19rem -2.49rem whitesmoke,
    2.06rem 0.32rem white, 14.53rem -3.38rem #cccccc, -7.64rem 0.83rem #e8e8e8,
    7.26rem 0.85rem #fafafa, 2.05rem 1.03rem #e3e3e3, 0.85rem 1.16rem #d1d1d1,
    11.04rem -1.21rem #f0f0f0, 6.49rem -3.04rem #dbdbdb,
    -3.13rem 3.13rem whitesmoke, 4.91rem -4.19rem #c9c9c9,
    -14.05rem 3.97rem #cfcfcf, 0.3rem -0.81rem #e6e6e6;
}
.stars:before,
.stars:after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  box-shadow: inherit;
}
.stars:before {
  transform: translateZ(-3rem);
  animation: fade1 3s linear infinite;
}
.stars:after {
  transform: translateZ(-6rem);
  animation: fade2 3s linear infinite;
}
@keyframes fly {
  from {
    transform: translateZ(0rem);
  }
  to {
    transform: translateZ(3rem);
  }
}
@keyframes fade1 {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
.plan-screen .copyright {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.14rem;
  z-index: 1000;
  line-height: 0.36rem;
  color: rgba(255, 255, 255, 0.8);
}
/* header */
.plan-screen .header {
  height: 1.08rem;
  position: relative;
}
.plan-screen .header .title {
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.5rem;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-shadow: rgb(0, 117, 255) 0px 0px 8px;
  color: #fff;
}
.plan-screen .header .date,
.plan-screen .header span {
  font-size: 0.24rem;
  line-height: 1.18rem;
  color: #fff;
}
.plan-screen .header .date {
  padding-left: 0.75rem;
}
.plan-screen .header span {
  padding-right: 0.75rem;
}
/* 头部动画样式 */
.head_title {
  text-align: center;
  color: #7ffcf8;
  height: 1.2rem;
  position: relative;
}
.head_title .headImg {
  display: none;
}
.head_title .arrow_img {
  animation-name: ln-10-1;
  -webkit-animation-name: ln-10-1;
  animation-duration: 8s;
  -webkit-animation-duration: 8s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  position: absolute;
  top: 20%;
  left: 5%;
  width: 0.05rem;
  z-index: 888;
  height: 0.05rem;
  border-radius: 50%;
  opacity: 1;
}
@keyframes ln-10-1 {
  0% {
    width: 0.05rem;
    opacity: 1;
    top: 20%;
    left: 5%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  10% {
    width: 2.5rem;
    opacity: 1;
    top: 20%;
    left: 10%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  20% {
    width: 0.1rem;
    opacity: 1;
    top: 20%;
    left: 31%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  30% {
    width: 0.05rem;
    opacity: 1;
    top: 60%;
    left: 34%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  45% {
    width: 2.5rem;
    opacity: 1;
    top: 60%;
    left: 34%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  60% {
    width: 0.05rem;
    opacity: 1;
    top: 60%;
    left: 66%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  70% {
    width: 0.1rem;
    opacity: 1;
    top: 20%;
    left: 69%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  90% {
    width: 1rem;
    opacity: 1;
    top: 20%;
    left: 90%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  100% {
    width: 0.05rem;
    opacity: 1;
    top: 20%;
    left: 98%;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    -moz-transform: rotate(0);
  }
}
.plan-screen .screen-content {
  height: calc(100% - 1.4rem);
  padding: 0 0.45rem 0.3rem;
  flex-direction: column;
  width: calc(100% - 0.9rem);
}
/* 上 */
.top-cont {
  height: calc(100% - 3rem);
}
.top-cont .cont,
.bottom-cont .cont {
  height: calc(100% - 0.45rem);
  background: #021e36;
  padding: 0 0.2rem 0.05rem;
  overflow: hidden;
}
.w-460 {
  width: 4.6rem;
}
.screen-title {
  background-image: url(../../assets/images/plan/xian.png);
  background-position: 0 bottom;
  background-size: 100% 0.1rem;
  background-repeat: no-repeat;
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.4rem;
  padding-bottom: 0.1rem;
  padding-left: 0.35rem;
  position: relative;
  font-weight: 400;
  box-sizing: border-box;
}
.screen-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.12rem;
  width: 0.33rem;
  height: 0.2rem;
  background-image: url(../../assets/images/plan/biaoti.gif);
  background-size: 100% 100%;
}
.screen-title h3 {
  color: #fff;
  font-weight: 500;
}
.screen-title .more {
  margin-top: 0.13rem;
  width: 0.89rem;
  height: 0.21rem;
  background: url(../../assets/images/plan/more.png) no-repeat 0 0 / 100% 100%;
  display: inline-block;
  text-indent: 2rem;
  cursor: pointer;
  z-index: 999;
}
/* 来源统计 */
.percentage-list {
  display: flex;
  flex-direction: column;
  height: calc(100% - 0.2rem);
}
.percentage-list li {
  line-height: 0.35rem;
  flex: 1;
  cursor: pointer;
}
.percentage-list .top {
  display: flex;
  justify-content: space-between;
}
.percentage-list .top span {
  color: #ddd;
  font-size: 0.16rem;
  font-weight: 600;
}
.percentage-list .top strong {
  font-weight: 600;
  font-size: 0.16rem;
  color: #0ab5de;
}
.progress {
  height: 0.07rem;
  background: #243b5c;
  overflow: visible;
  position: relative;
}
.progress .progress-bar {
  position: relative;
  height: 0.1rem;
  background-image: linear-gradient(
    to right,
    rgb(18, 128, 220),
    rgb(51, 211, 255)
  );
  animation: animate-positive 10s linear;
}
.progress .progress-bar:after {
  content: "";
  width: 0.12rem;
  height: 0.12rem;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  right: -0.05rem;
  top: -0.06rem;
  border: 0.04rem solid rgb(51, 211, 255);
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
/* 媒体类型分布 */
#echarts1,
#echarts2 {
  width: 100%;
  height: 100%;
}
.center-cont {
  margin: 0 0.35rem;
}
/* 数据 */
.data {
  text-align: center;
}
.data .tag {
  width: 2.7rem;
  height: 0.68rem;
  font-size: 0.24rem;
  font-weight: 600;
  line-height: 0.57rem;
  background-image: url(../../assets/images/plan/btn-bg.png) !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  color: #fff;
}
.data .num {
  font-size: 0.38rem;
  line-height: 0.45rem;
  font-family: "myFirstFont";
  color: #fff;
}
.data .num:hover {
  cursor: pointer;
}
.data li:nth-child(2) .num {
  background: linear-gradient(270deg, red 0%, #ff85a2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* 词云 */
.earth-box {
  width: 100%;
  height: calc(100% - 1.14rem);
  position: relative;
}
.earth,
.tagcloud_box {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: .18rem;
}

.earth {width: 4rem;
  height: 4rem;
  background-image: url(../../assets/images/plan/earth.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-position: 0 0;
  z-index: 10;
}
.tagcloud_box {
  z-index: 20;width: 5rem;
  height: 5rem;
}
/* .earth-box >>> .tag-cloud p {
  font-weight: 600;
} */
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-ms-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.Rotation {
  transform-origin: center;
  animation: rotation 120s linear infinite;
  -moz-animation: rotation 120s linear infinite;
  -webkit-animation: rotation 120s linear infinite;
  -o-animation: rotation 120s linear infinite;
  animation: rotation 120s linear infinite;
}
.left-aside,
.right-aside {
  width: 20%;
  height: 80%;
  background-size: 90% 90%;
  background-repeat: no-repeat;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.left-aside {
  background-image: url(../../assets/images/plan/left.png);
  animation-name: leftMove;
  left: 0;
}
.right-aside {
  background-image: url(../../assets/images/plan/right.png);
  animation-name: rightMove;
  right: 0;
}
@keyframes leftMove {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 50%;
  }
}
@keyframes rightMove {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -50%;
  }
}
/* 标签云 */
.fz_14 {
  font-size: 0.18rem;
}
.fz_18 {
  font-size: 0.22rem;
}
.fz_22 {
  font-size: 0.24rem;
}
.fz_26 {
  font-size: 0.28rem;
}
.fz_28 {
  font-size: 0.32rem;
}
/* 向上滚动 */
.marquee-wrap {
  height: 1.9rem;
  overflow: hidden;
}
.animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateY(-0.38rem);
}
.screen-list {
  line-height: 0.3rem;
  font-size: 0.14rem;
}
.screen-list .title span {
  line-height: 0.4rem;
}
.screen-list .title span:nth-child(1) {
  width: 0.45rem;
  margin-left: 0.15rem;
}
.screen-list .title span:last-child {
  padding-right: 0.1rem;
}
.screen-list span,
.screen-list a {
  color: #fff;
}
.screen-list .item {
  width: calc(100% - 0.22rem);
  float: left;
  padding: 0.06rem 0.1rem;
  font-size: 0.14rem;
  border: 1px solid transparent;
  height: 0.24rem;
  line-height: 0.24rem;
  cursor: pointer;
  display: flex;
}
.screen-list .item .flex-1 {
  width: calc(100% - 0.5rem);
  float: left;
}
.screen-list .item:hover {
  background: #1a3c72;
  border-color: #188cf8;
  border-radius: 0.02rem;
}
.screen-list span.num {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.26rem;
  border-radius: 50%;
  border: 1px solid #188cf8;
  float: left;
  line-height: 0.22rem;
  text-align: center;
}
.screen-list-title {
  line-height: 0.24rem;
  height: 0.24rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  float: left;
  flex: 1;
}
.screen-list .tag {
  width: 0.8rem;
  float: left;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.screen-list .time {
  width: 2rem;
  text-align: center;
}
.mar-l-20 {
  margin-left: 0.2rem;
}
/* screen-dialog */
.screen-dialog >>> .el-dialog {
  width: 80%;
  height: 81%;
  background: url(../../assets/images/plan/popOutBg.png) no-repeat 0 0 / 100%
    100%;
}
.screen-dialog >>> .el-dialog__body {
  height: calc(100% - 0.25rem);
  box-sizing: border-box;
}
.screen-dialog-title {
  font-size: 0.28rem;
  color: #fff;
  width: 3rem;
  height: 0.7rem;
  line-height: 0.7rem;
  color: 500;
  text-align: center;
  background: url(../../assets/images/plan/title_bg.png) no-repeat 0 0 / 100%
    100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.screen-dialog .cont {
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  width: 96%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.sub-title {
  line-height: 0.32rem;
}
.screen-dialog >>> .el-form-item__label,
.screen-dialog .sub-title span,
.screen-dialog >>> .el-link--inner,
.sub-title >>> .icon-xiazai {
  color: #fff;
  font-size: 0.16rem;
}
.screen-dialog >>> .el-input__inner {
  background: none;
  width: 1.6rem;
}
.sub-title >>> .icon-xiazai {
  margin-right: 0.1rem;
}
.screen-dialog .item {
  padding: 0.15rem 0 0.05rem;
}
.screen-dialog .item:not(:last-child) {
  border-bottom: 1px solid #31adea;
}
.screen-dialog .item > div {
  align-items: stretch;
}
.screen-dialog .item .t-c {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.12rem;
  color: #efefef;
}
.screen-dialog .xxlb-title {
  height: 0.45rem;
  line-height: 0.45rem;
  background: #31adea;
  font-size: 0.16rem;
  color: #fff;
  text-align: center;
}
.screen-dialog .title {
  font-weight: 600;
  font-size: 0.14rem;
  width: calc(100% - 2rem);
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  float: left;
  color: #fff;
}
.screen-dialog .summary {
  font-size: 0.14rem;
  line-height: 0.24rem;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color:rgba(255,255,255,.8);
  margin-top: 0.1rem;
}
.screen-dialog .info span {
  line-height: 0.4rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.12rem;
}
.screen-dialog .info span:not(:last-child) {
  margin-right: 0.3rem;
}
.screen-dialog .icon-yuanfabiaoqian {
  background-image: url(../../assets/images/common/yf.svg);
}
.screen-dialog .icon-zhuanfabiaoqian {
  background-image: url(../../assets/images/common/zf.svg);
}
.screen-dialog >>> .el-dialog__headerbtn {
  top: -0.23rem;
  right: -0.25rem;
}
.screen-dialog >>> .el-dialog__close {
  width: 0.44rem;
  height: 0.44rem;
  background: url(../../assets/images/plan/close_btn.png) no-repeat 0 0 / 100%
    100%;
}
.screen-dialog >>> .el-dialog__close::before {
  content: "";
}
.screen-dialog >>> .el-pagination button,
.screen-dialog >>> .el-pager li {
  background: none;
  color: #fff;
  font-size: 0.13rem;
}
/* 信息详情 */
.article-dialog .cont {
  top: 0.9rem;
}
.article-dialog .article-title {
  font-size: 0.16rem;
  line-height: 0.32rem;
}
.article-dialog .article-title >>> .el-button {
  float: none;
  margin: 0 0 0 0.1rem;
}
.scroll_content li {
  height: 2.5rem;
  line-height: 2.5rem;
}
.article-dialog .info {
  line-height: 0.6rem;
  border-bottom: 1px solid #31adea;
}
.article-dialog .article-cont {
  text-indent: 2em;
  font-size: 0.14rem;
  line-height: 0.3rem;
  margin: 0.3rem 0;
  overflow: auto;
}
.article-dialog .article-cont p {
  margin-bottom: 0.1rem;
}
.article-dialog >>> .el-button--primary.is-plain {
  background: rgba(11, 72, 159, 0.5);
  border-color: #1890ff;
  color: #fff;
}
.screen .bottom-cont {
  height: 2.9rem;
  overflow: hidden;
}
.screen .echarts_empty {
  height: 100%;
}
.screen .echarts_empty .img {
  background-image: url(../../assets/images/plan/kong.png);
  width: 0.94rem;
  height: 1.2rem;
}
.screen >>> .fmg.el-button--primary,
.screen >>> .mg.el-button--primary {
  width: 0.45rem;
  height: 0.2rem;
  line-height: 0.2rem;
  font-size: 0.12rem;
}
</style>
